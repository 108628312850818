import React from "react";
import { useImageMeteDataContext } from "../context";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ImageGrid, Loader, NoData } from "../components";
import { Link } from "react-router-dom";
const SearchResults = () => {
  const { state } = useImageMeteDataContext();
  const { searchResults } = state;

  if (state.loading) {
    return <Loader />;
  }
  return (
    <div className=" max-w-6xl m-auto py-8">
      <div className="flex flex-row justify-between items-center mb-7">
        <h1 className="text-xl text-indigo-500 font-bold">Search Results</h1>
        <Link
          to="/search"
          className="rounded-full bg-indigo-600  p-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <MagnifyingGlassIcon className="h-5 w-5" />
        </Link>
      </div>
      {searchResults.length > 0 ? (
        <ImageGrid data={searchResults} />
      ) : (
        <NoData message={"No Search Results"} />
      )}
    </div>
  );
};

export default SearchResults;
