import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  MagnifyingGlassIcon,
  XMarkIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { useImageMeteDataContext } from "../context";
import { showErrorToast, showInfoToast } from "../utils/toast";
import { API } from "../service/Api";

const Search = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      query: [{ col: "", keyword: "" }],
    },
    mode: "onChange",
  });
  const { fields, append, remove } = useFieldArray({ control, name: "query" });

  const { state, getImageMeta, dispatch } = useImageMeteDataContext();

  const [suggestions, setSuggestions] = useState([]);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  // used for field name
  const [fIdx, setFIdx] = useState();

  const navigate = useNavigate();

  const search = async (data) => {
    // console.log(data);
    try {
      const res = await API.searchImageMeta(data);

      if (res.error) {
        showErrorToast(res.message);
      } else if (res.data.length === 0) {
        showInfoToast("No reocds match your search");
      } else {
        dispatch({ type: "SEARCH", payload: res.data });
        navigate("/results", { state: { col: "search" } });
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  const fetchSuggestions = (value) => {
    const { meta } = state;
    let _list = [];
    let _suggestions = meta.map((obj) => obj[value]);

    if (_suggestions.length > 0 && value === "keywords") {
      _suggestions.map((obj) => {
        let _keywords = JSON.parse(obj);
        if (!Array.isArray(_keywords)) {
          _keywords = [_keywords];
        }
        _list = _list.concat(..._keywords);
      });
      _suggestions = _list;
    }

    setSuggestions([...new Set(_suggestions)]);
  };

  const onChange = (e) => {
    const userInput = e.currentTarget.value;

    setFIdx(e.target.name.split(".")[1]);

    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setActiveSuggestion(0);
    setShowSuggestions(true);
    setFilteredSuggestions(filteredSuggestions);
  };

  const onClick = (e) => {
    setActiveSuggestion(0);
    setShowSuggestions(false);
    setFilteredSuggestions([]);
    setValue(`query.${fIdx}.keyword`, e.currentTarget.innerText);
  };
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      showSuggestions(false);
      setValue(`query.${fIdx}.keyword`, filteredSuggestions[activeSuggestion]);
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      setActiveSuggestion(activeSuggestion - 1);
    } else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  };
  let isloggedIn = localStorage.getItem("loggedIn");
  useEffect(() => {
    getImageMeta();
  }, []);

  return (
    <div className="relative grid gap-3 grid-cols-1 p-10  bg-slate-300 h-screen">
      <div>
        <div className="link my-5 md:mx-7">
          <Link
            className={isloggedIn === null ? "hidden" : "block"}
            to="/admin"
          >
            <span className=" flex gap-2 items-center text-gray-600 hover:text-indigo-500">
              <ArrowLeftIcon className="w-5 h-5" /> Back to Main Dashboard
            </span>
          </Link>
        </div>
        {/* <div className="w-full  border-2 p-10"> */}
        {/* <h1 className="text-lg font-bold underline">Order Images</h1> */}
        {/* Ectracted Data Equals to Object Name */}
        {/* <div className=" py-3  mt-2">
            <Link
              to="/image"
              state={{ col: "object_name" }}
              className="hover:underline hover:text-indigo-500"
            >
              Alphabetical List of Captions
            </Link>
          </div> */}
        {/* Ectracted Data Equals to Objec By Line */}
        {/* <div className=" py-3  mt-2">
            <Link
              to="/image"
              state={{ col: "by_line" }}
              className="hover:underline hover:text-indigo-500"
            >
              Alphabetical List of Labels
            </Link>
          </div> */}
        {/* Ectracted Data Equals to Captions */}
        {/* <div className=" py-3  mt-2">
            <Link
              to="/image"
              state={{ col: "caption" }}
              className="hover:underline hover:text-indigo-500"
            >
              Alphabetical List of Notes
            </Link>
          </div> */}
        {/* Ectracted Data Equals to Keywords */}
        {/* <div className=" py-3  mt-2">
            <Link
              to="/image"
              state={{ col: "keywords" }}
              className="hover:underline hover:text-indigo-500"
            >
              Alphabetical List of Keywords
            </Link>
          </div> */}
        {/* Ectracted Data Equals to Writer */}
        {/* <div className=" py-3  mt-2">
            <Link
              to="/image"
              state={{ col: "writer" }}
              className="hover:underline hover:text-indigo-500"
            >
              Alphabetical List of Events / Places
            </Link>
          </div> */}
        {/* Ectracted Data Equals to Writer */}
        {/* <div className=" py-3 mt-2">
          <Link
            to="/image"
            state={{ col: "wrtiter" }}
            className="hover:underline hover:text-indigo-500 "
          >
            Alphabetical List of Places
          </Link>
        </div> */}
        {/* </div> */}
        <form
          onSubmit={handleSubmit(search)}
          className="w-full col-span-3 md:p-10"
        >
          <h1 className="text-lg font-bold underline">Search Images</h1>
          {fields.map((feild, index) => {
            return (
              <div key={feild.id} className="flex flex-row relative">
                <div className="w-full z-0 flex flex-col  justify-center items-center gap-4 m-1 md:pr-16 pr-10">
                  <div className="flex-1">
                    {/* render the filtering methods */}
                    {index >= 1 ? (
                      <div className="flex justify-start items-center gap-2 mt-2">
                        <div>
                          <label className="flex justify-between items-center">
                            <input
                              type="radio"
                              {...register(`query.${index}.operator`, {
                                required: true,
                              })}
                              value="AND"
                              className="border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <span className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              AND
                            </span>
                          </label>
                        </div>
                        <div>
                          <label className="flex justify-between items-center">
                            <input
                              type="radio"
                              {...register(`query.${index}.operator`, {
                                required: true,
                              })}
                              value="OR"
                              className="border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <span className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              OR
                            </span>
                          </label>
                        </div>
                        <div>
                          <label className="flex justify-between items-center">
                            <input
                              type="radio"
                              {...register(`query.${index}.operator`, {
                                required: true,
                              })}
                              value="NOT"
                              className="border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <span className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              NOT
                            </span>
                          </label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex flex-row justify-between items-start w-full gap-3">
                    <div className="flex-1">
                      <select
                        {...register(`query.${index}.col`, {
                          required: true,
                        })}
                        onChange={(e) => {
                          fetchSuggestions(e.target.value);
                          setValue(`query.${index}.keyword`, "");
                        }}
                        className="block w-full  rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        {/* <option value="object_name">Caption</option> */}
                        <option value="caption">Notes</option>
                        <option value="date">Date</option>
                        <option value="keywords">Keywords</option>
                        <option value="writer">Events / Places</option>
                        <option value="by_line">Label</option>
                      </select>
                    </div>
                    {/* Render the input */}
                    <div className="flex-1 relative">
                      <React.Fragment>
                        <input
                          {...register(`query.${index}.keyword`, {
                            required: true,
                          })}
                          type="text"
                          onChange={onChange}
                          onKeyDown={onKeyDown}
                          autoComplete="off"
                          className="block z-10 w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />

                        {fIdx == index ? (
                          showSuggestions ? (
                            filteredSuggestions.length ? (
                              <ul className="absolute z-50 bg-white w-full max-h-96 overflow-auto shadow-sm border py-1">
                                {filteredSuggestions.map((suggestion) => {
                                  return (
                                    <li
                                      className="px-3 py-[2px] hover:bg-[#0A95FF] hover:text-white text-sm"
                                      key={suggestion}
                                      onClick={onClick}
                                    >
                                      {suggestion}
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <div className=" absolute z-50 font-semibold text-gray-500 p-2">
                                <em>No suggestions!</em>
                              </div>
                            )
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    </div>
                  </div>
                </div>
                {index !== 0 ? (
                  <button
                    type="button"
                    className="absolute bottom-1 right-2 bg-rose-500 hover:bg-rose-600 text-white h-9 w-9 grid place-items-center rounded-sm"
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                ) : (
                  ""
                )}
              </div>
            );
          })}
          <div className="flex flex-row justify-between items-center mt-5 p-1">
            <button
              type="button"
              className="rounded-full text-indigo-600 px-4 py-2.5 text-sm border border-indigo-500 hover:bg-indigo-500 hover:text-white"
              onClick={() => {
                append({ col: "", keyword: "", operator: "AND" });
              }}
            >
              <span> Add Another Query</span>
            </button>

            <button
              type="submit"
              className="md:mr-16 rounded-full bg-indigo-600  p-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <MagnifyingGlassIcon className="h-5 w-5" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Search;
