import React from "react";
import { useImageMeteDataContext } from "../context";
import { XMarkIcon } from "@heroicons/react/24/outline";

const ImageViewer = () => {
  const { openImageModal, setOpenImageModal, viewImageUrl } =
    useImageMeteDataContext();

  return (
    <div className=" fixed top-0 left-0 w-screen h-screen overflow-hidden bg-black/80 grid place-items-center z-50">
      <button
        className="absolute right-8 top-4  cursor-pointer text-white/80 hover:text-white bg-black/20 h-12 w-12 hover  p-2 rounded-full items-center"
        onClick={() => setOpenImageModal(!openImageModal)}
      >
        <XMarkIcon />
      </button>

      <img src={viewImageUrl} alt={viewImageUrl} className="max-w-[80%]" />
    </div>
  );
};

export default ImageViewer;
