import {
  useEffect,
  useContext,
  createContext,
  useState,
  useReducer,
} from "react";

import { initialState, reducer } from "./store";
import { API } from "../service/Api";
import { showErrorToast } from "../utils/toast";

const ImageMeteDataContext = createContext();

export const useImageMeteDataContext = () => {
  return useContext(ImageMeteDataContext);
};

export const ImageMeteDataContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [openSideBar, setOpenSideBar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [viewImageUrl, setViewImageUrl] = useState(false);

  useEffect(() => {}, []);

  // GET Image META

  const getImageMeta = async () => {
    try {
      dispatch({ type: "LOADING", payload: true });
      const meta = await API.getImageMeta();

      if (meta.error) {
        showErrorToast("somthing went wrong");
      } else {
        dispatch({ type: "META", payload: meta.data });
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      dispatch({ type: "LOADING", payload: false });
    }
  };

  const value = {
    state,
    dispatch,
    openSideBar,
    setOpenSideBar,
    openModal,
    setOpenModal,
    getImageMeta,
    openImageModal,
    setOpenImageModal,
    viewImageUrl,
    setViewImageUrl,
  };

  return (
    <ImageMeteDataContext.Provider value={value}>
      {children}
    </ImageMeteDataContext.Provider>
  );
};
