import http from "./http";

export const API = {
  getImageMeta: async () => {
    try {
      const res = await http.get("/meta");
      const meta = res.data;

      if (meta.error) {
        throw new Error(meta.error);
      } else {
        return meta;
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  postImageMeta: async (body) => {
    try {
      const res = await http.post("/meta", { ...body });
      const meta = res.data;

      if (meta.error) {
        throw new Error(meta.error);
      } else {
        return meta;
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  deleteImageMeta: async (id) => {
    try {
      const res = await http.delete(`/meta/${id}`);
      const meta = await res.data;
      if (meta.error) {
        throw new Error(meta.error);
      } else {
        return meta;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  putImageMeta: async (data) => {
    try {
      const res = await http.put(`/meta/${data.id}`, { ...data });
      const meta = await res.data;
      if (meta.error) {
        throw new Error(meta.error);
      } else {
        return meta;
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  uploadFiles: async (formData) => {
    try {
      const res = await http.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { error, message } = await res.data;

      if (error) {
        throw new Error(error);
      } else {
        return message;
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  getImageMeteById: () => {},

  searchImageMeta: async (query) => {
    try {
      const res = await http.post("/search", { ...query });
      const { error, message } = await res.data;

      if (error) {
        throw new Error(error);
      } else {
        return res.data;
      }
    } catch (error) {
      throw new Error(error);
    }
  },
};
