import React, { useState } from "react";
import { useImageMeteDataContext } from "../context";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";

const ImageModal = ({ image }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { setOpenImageModal, setOpenModal, openModal, setViewImageUrl } =
    useImageMeteDataContext();

  const handleFullScreenClick = (url) => {
    setOpenModal(!openModal);
    setOpenImageModal(true);
    setViewImageUrl(url);
    setIsFullScreen(!isFullScreen);
  };

  let _keywords = JSON.parse(image.keywords);
  if (!Array.isArray(_keywords)) {
    _keywords = [_keywords];
  }

  return (
    <>
      <div className="flex flex-row justify-between gap-8 items-start mt-4">
        <div className="relative w-1/3 max-h-60  overflow-hidden">
          <img src={image.imageUrl} className="w-full" />

          <button
            className="absolute right-4 top-4  cursor-pointer text-white/80 hover:text-white bg-black/20 h-10 w-10 hover  p-2 rounded-full items-center"
            onClick={() => handleFullScreenClick(image.imageUrl)}
          >
            <ArrowsPointingOutIcon />
          </button>
        </div>
        <div className="flex flex-1 flex-col">
          <div className="sm:grid sm:grid-cols-4 sm:items-start  sm:border-gray-200">
            <p className="border px-2 py-1 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              <strong>Date</strong>
            </p>
            <p className="text-sm border px-2 py-1 mt-1 sm:col-span-3 sm:mt-px sm:pt-2">
              {image.date}
            </p>
          </div>
          <div className="sm:grid sm:grid-cols-4 sm:items-start  sm:border-gray-200">
            <p className="border px-2 py-1 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              <strong>Name</strong>
            </p>
            <p className="text-sm border px-2 py-1 mt-1 sm:col-span-3 sm:mt-px sm:pt-2">
              {image.image}
            </p>
          </div>
          <div className="sm:grid sm:grid-cols-4 sm:items-start  sm:border-gray-200">
            <p className="border px-2 py-1 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              <strong>Label</strong>
            </p>
            <p className="text-sm border px-2 py-1 mt-1 sm:col-span-3 sm:mt-px sm:pt-2">
              {image.by_line}
            </p>
          </div>
          <div className="sm:grid sm:grid-cols-4 sm:items-start  sm:border-gray-200">
            <p className="border px-2 py-1 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              <strong>Notes</strong>
            </p>
            <p className="text-sm border px-2 py-1 mt-1 sm:col-span-3 sm:mt-px sm:pt-2">
              {image.caption}
            </p>
          </div>
          {/* <div className="sm:grid sm:grid-cols-4 sm:items-start  sm:border-gray-200">
            <p className="border px-2 py-1 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              <strong>Caption</strong>
            </p>
            <p className="text-sm border px-2 py-1 mt-1 sm:col-span-3 sm:mt-px sm:pt-2">
              {image.object_name}
            </p>
          </div> */}
          <div className="sm:grid sm:grid-cols-4 sm:items-start  sm:border-gray-200">
            <p className="border px-2 py-1 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              <strong>Events / Places</strong>
            </p>
            <p className="text-sm border px-2 py-1 mt-1 sm:col-span-3 sm:mt-px sm:pt-2">
              {image.writer}
            </p>
          </div>
          <div className="sm:grid sm:grid-cols-4 sm:items-start  sm:border-gray-200">
            <p className="border px-2 py-1 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              <strong> Keywords</strong>
            </p>
            <p className="text-xs border px-2 py-1 flex flex-wrap mt-1 sm:col-span-3 sm:mt-px sm:pt-2">
              {_keywords?.map((keyword, index) => (
                <span key={index} className="mr-1 p-2 bg-gray-100">
                  {keyword}
                </span>
              ))}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageModal;
