import React, { useCallback, useEffect, useState } from "react";
import { useImageMeteDataContext } from "../context";
import { FileUploader, Loader, NoData, Table } from "../components";
import exifr from "exifr";
import { DateTime } from "luxon";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { showErrorToast, showSuccessToast } from "../utils/toast";
import { API } from "../service/Api";

const options = {
  chunkLimit: 5,
  chunkSize: 65536,
  chunked: true,
  exif: true,
  firstChunkSizeBrowser: 65536,
  firstChunkSizeNode: 512,
  gps: true,
  icc: true,
  ifd0: true,
  ifd1: true,
  ihdr: true,
  interop: true,
  iptc: true,
  jfif: true,
  makerNote: true,
  mergeOutput: true,
  multiSegment: true,
  pick: [],
  reviveValues: true,
  sanitize: true,
  silentErrors: true,
  skip: [],
  tiff: true,
  translateKeys: true,
  translateValues: true,
  userComment: true,
  xmp: true,
};

const Uploads = () => {
  const { state, dispatch } = useImageMeteDataContext();

  const [processedImages, setProcessedImages] = useState([]);
  const [uplaodSize, setUploadSize] = useState(0);

  const { uploadedImages } = state;
  const columns = React.useMemo(
    () => [
      {
        Header: "File",
        accessor: "thumbnailUrl",
        Cell: ({ value }) => (
          <div className="h-16 w-16 overflow-hidden">
            <img src={value} alt="" />
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "Image",
      },
      // {
      //   Header: "Caption",
      //   accessor: "metadata.ObjectName",
      // },
      {
        Header: "Label",
        accessor: "metadata.OrigiinalTransmissdinReference",
      },
      {
        Header: "Notes",
        accessor: "metadata.SpecialInstruction",
      },
      {
        Header: "Keywords",
        accessor: "metadata.Keywords",
      },
      {
        Header: "Events / Places",
        accessor: "metadata.Caption",
      },
      {
        Header: "Date",
        accessor: "metadata.CreateDate",
        Cell: ({ value }) => (
          <div className="max-h-24">
            {value !== "N/A"
              ? DateTime.fromJSDate(new Date(value)).toFormat("yyyy-MM-dd")
              : "N/A"}
          </div>
        ),
      },
    ],
    []
  );

  const handleDeleteBtnClk = async (image) => {
    const index = processedImages.indexOf(image);
    // console.log(index);

    if (index < uploadedImages.length) {
      uploadedImages.splice(index, 1);

      dispatch({
        type: "UPLOADED_IMAGES",
        payload: uploadedImages,
      });

      // ProcessImages();
    }
  };

  const actions = [
    {
      icon: TrashIcon,
      handler: handleDeleteBtnClk,
      styles: "w-5 h-5 text-red-500",
    },
  ];

  // tale config
  const config = {
    isSorted: false,
    isPaged: false,
  };

  // Refresh UseEffect
  useEffect(() => {
    if (uploadedImages.length > 0) {
      ProcessImages();
    }
  }, [uploadedImages.length]);

  useEffect(() => {}, [uploadedImages]);

  const ProcessImages = useCallback(async () => {
    let size = 0;
    const iptcDataPromises = uploadedImages.map(async (imageData) => {
      size += imageData.size;
      const metadata = await exifr.parse(imageData, options);
      return metadata;
    });

    setUploadSize((size / 1048576).toFixed(2));

    const iptcDataArray = await Promise.all(iptcDataPromises);

    // console.log(iptcDataArray);

    const propertiesToCheck = [
      "ObjectName",
      "OrigiinalTransmissdinReference",
      "SpecialInstruction",
      "Keywords",
      "Caption",
      "CreateDate",
      "Image",
    ];

    const filteredMetadata = [];

    iptcDataArray.forEach((metadata) => {
      const filteredMetadataItem = {};
      propertiesToCheck.forEach((property) => {
        if (property === "ObjectName") {
          filteredMetadataItem[property] =
            metadata && metadata.hasOwnProperty(property)
              ? metadata[property]
              : // : "N/A";
              metadata.hasOwnProperty("ImageDescription")
              ? metadata["ImageDescription"]
              : "N/A";
        } else if (property === "Keywords") {
          filteredMetadataItem[property] =
            metadata && metadata.hasOwnProperty(property)
              ? metadata[property] || "N/A"
              : "N/A";

          if (filteredMetadataItem[property] === "N/A") {
            filteredMetadataItem[property] = ["N/A"];
          }
        } else
          filteredMetadataItem[property] =
            metadata && metadata.hasOwnProperty(property)
              ? metadata[property] || "N/A"
              : "N/A";
      });
      filteredMetadata.push(filteredMetadataItem);
    });

    const processedImages = filteredMetadata.map((metadata, index) => {
      // console.log(uploadedImages[index]);
      return {
        metadata,
        thumbnailUrl: URL.createObjectURL(uploadedImages[index]),
        Image: uploadedImages[index].name,
      };
    });
    // console.log(processedImages);
    setProcessedImages(processedImages);
  }, [uploadedImages]);

  // Call URL.revokeObjectURL to release memory when component unmounts
  useEffect(() => {
    return () => {
      processedImages.forEach((image) => {
        URL.revokeObjectURL(image.thumbnailUrl);
      });
    };
  }, [uploadedImages]);

  const handleUploadClick = async () => {
    const formData = new FormData();

    if (uploadedImages.length === processedImages.length)
      for (let i = 0; i < uploadedImages.length; i++) {
        formData.append("image", uploadedImages[i]);
        formData.append("image", uploadedImages[i].name);
        formData.append(
          "caption",
          processedImages[i].metadata.SpecialInstruction
        );
        formData.append(
          "by_line",
          processedImages[i].metadata.OrigiinalTransmissdinReference
        );
        formData.append(
          "keywords",
          JSON.stringify(processedImages[i].metadata.Keywords)
        );
        formData.append("writer", processedImages[i].metadata.Caption);
        formData.append("object_name", processedImages[i].metadata.ObjectName);
        formData.append("date", processedImages[i].metadata.CreateDate);
      }

    try {
      dispatch({ type: "LOADING", payload: true });
      const message = await API.uploadFiles(formData);
      showSuccessToast(message);
    } catch (error) {
      showErrorToast(error);
    } finally {
      dispatch({
        type: "UPLOADED_IMAGES",
        payload: [],
      });
      dispatch({ type: "LOADING", payload: false });
    }
  };

  const handleCancelClick = () => {
    dispatch({
      type: "UPLOADED_IMAGES",
      payload: [],
    });
  };

  if (state.loading)
    return <Loader message={"Please wait while we uploading your images"} />;

  return (
    <>
      {uploadedImages.length === 0 ? (
        <FileUploader />
      ) : (
        <>
          <div className="flex flex-row justify-between items-center my-4">
            <p className="text-sm font-semibold text-indigo-500">
              <span>{uploadedImages.length} </span>
              files are uploaded.
            </p>
            <p className="font-semibold text-black ">
              Upload Size:{uplaodSize} MB
              {uplaodSize > 200 ? (
                <span className="text-rose-500">Upload limit is 200 Mb</span>
              ) : (
                ""
              )}
            </p>
          </div>
          <Table
            columns={columns}
            data={processedImages}
            actions={actions}
            config={config}
          />
          <div className="flex flex-row justify-end gap-2 mt-6">
            <button
              onClick={handleCancelClick}
              type="button"
              className="rounded bg-gray-600 px-2 py-1 self-end text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            >
              Cancel
            </button>
            <button
              disabled={uplaodSize > 200 ? true : false}
              onClick={handleUploadClick}
              type="button"
              className="rounded bg-indigo-600 px-2 py-1 self-end text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Upload
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Uploads;
