import React, { useEffect } from "react";
import { ImageGrid, NoData, OrderingList } from "../components";
import { Link } from "react-router-dom";
import { useImageMeteDataContext } from "../context";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const OrderedImage = () => {
  const { state, getImageMeta } = useImageMeteDataContext();
  const { meta } = state;

  useEffect(() => {
    getImageMeta();
  }, []);

  return (
    <div className=" max-w-6xl m-auto py-8">
      <div className="flex flex-row justify-between items-center mb-7">
        <h1 className="text-xl text-indigo-500 font-bold">Ordered Images</h1>
        <Link
          to="/search"
          className="rounded-full bg-indigo-600  p-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <MagnifyingGlassIcon className="h-5 w-5" />
        </Link>
      </div>
      {meta.length > 0 ? (
        <OrderingList data={meta} />
      ) : (
        <NoData message={"No Search Results"} />
      )}
    </div>
  );
};
export default OrderedImage;
