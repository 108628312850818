import { DateTime } from "luxon";

export const DATE = {
  parser: (date) => {
    const parsedDate = DateTime.fromJSDate(new Date(date));
    return parsedDate.toFormat("yyyy-MM-dd");
  },
  // use for converting the time for input type date
  to: (time) => {
    const parsedTime = DateTime.fromJSDate(new Date(time));

    return parsedTime.toFormat("yyyy-LL-dd");
  },
  from: (time) => {
    return DateTime(new Date(time), "hh:mm A").format("HH:mm");
  },
};
