import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import styled from "styled-components";

import { useTable, usePagination, useSortBy } from "react-table";
const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;
const Table = ({ columns, data, actions, config }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [
          {
            id: "image",
            desc: false,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <Styles>
        <div className="tableWrap block max-w-full	overflow-x-scroll	overflow-y-scroll h-Table-Max md:overflow-x-hidden">
          <table
            {...getTableProps()}
            className="min-w-full divide-y divide-gray-300"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      scope="col"
                      className="px-3 py-3.5 text-left text-base font-semibold text-gray-900 top-0 bg-white sticky top-8"
                    >
                      {config.isSorted ? (
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      ) : (
                        ""
                      )}
                      {column.render("Header")}
                    </th>
                  ))}
                  {actions.length !== 0 ? (
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {(config.isPaged ? page : rows).map((row, idx) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={idx % 2 === 0 ? undefined : "bg-gray-50"}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className=" whitespace-pre-wrap truncate px-3 py-4 text-base text-gray-500"
                        >
                          <div className="h-24 md:h-143 overflow-y-auto">
                            {cell.render("Cell")}
                          </div>
                        </td>
                      );
                    })}
                    {actions.length !== 0 ? (
                      <td>
                        <div className="flex flex-row justify-end items-center gap-2">
                          {actions.map((action, index) => (
                            <button
                              key={index}
                              // rowData={row.original}
                              className={action.styles}
                              onClick={() => action.handler(row.original)}
                            >
                              <action.icon />
                            </button>
                          ))}
                        </div>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {config.isPaged ? (
            <div className="mt-10 flex justify-between items-center">
              <div className="flex flex-row justify-between items-center gap-6">
                <button
                  className="h-8 w-8 hover:bg-gray-200 transition-all p-2"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <ChevronDoubleLeftIcon />
                </button>
                <button
                  className="h-8 w-8 hover:bg-gray-200 transition-all p-2"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <ChevronLeftIcon />
                </button>
                <button
                  className="h-8 w-8 hover:bg-gray-200 transition-all p-2"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <ChevronRightIcon />
                </button>
                <button
                  className="h-8 w-8 hover:bg-gray-200 transition-all p-2"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <ChevronDoubleRightIcon />
                </button>{" "}
              </div>

              <div className="flex flex-row justify-between items-center gap-2 text-sm">
                <span>
                  Page{" "}
                  <strong className="  font-bold text-indigo-500">
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
                <span>
                  | Go to page:{" "}
                  <input
                    className="w-[100px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    type="number"
                    // defaultValue={pageIndex + 1}
                    value={pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                  />
                </span>{" "}
                <select
                  className="max-w-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Styles>
    </>
  );
};

export default Table;
