import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { createBrowserHistory } from "history";

// pages
import * as Pages from "./pages";
// layouts
import * as Layouts from "./layouts";
// Context
import { ImageMeteDataContextProvider } from "./context";

// Private Route  / Protected Routes
function PrivateRoute({ children }) {
  const loggedIn = localStorage.getItem("loggedIn") === "true";

  if (!loggedIn) return <Navigate to="/" />;

  return children;
}

function App() {
  //  making back navigation back disable
  // const history = createBrowserHistory();

  // function handlePopstate(event) {
  //   history.go(1);
  // }

  // window.addEventListener("popstate", handlePopstate);

  // useEffect(() => {
  //   return () => {
  //     window.removeEventListener("popstate", handlePopstate);
  //   };
  // }, []);

  return (
    <ImageMeteDataContextProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/admin/*"
            element={
              <PrivateRoute>
                <Layouts.AdminLayout />
              </PrivateRoute>
            }
          >
            <Route path="" element={<Pages.Dashboard />} />
            <Route path="uploads" element={<Pages.Uploads />} />
            {/* wild card route for admin */}
            <Route path="*" element={<Navigate to="" />} />
          </Route>

          <Route path="/" element={<Pages.AuthPage />} />

          <Route path="/search" element={<Pages.Search />} />
          <Route path="/results" element={<Pages.SearchResults />} />
          <Route path="/image" element={<Pages.OrderedImage />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </ImageMeteDataContextProvider>
  );
}

export default App;
