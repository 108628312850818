import React from "react";
import { ThreeCircles } from "react-loader-spinner";

const Loader = ({ message }) => {
  return (
    <div className="grid place-items-center  h-screen w-full bg-slate-200/25">
      <div className="flex-col flex justify-center items-center">
        <ThreeCircles
          height="60"
          width="60"
          color="#4338CA"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="three-circles-rotating"
          outerCircleColor=""
          innerCircleColor=""
          middleCircleColor=""
        />
        <span className="mt-4">{message}</span>
      </div>
    </div>
  );
};

export default Loader;
