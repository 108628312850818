const DUMMY_USERS = {
  email: "davidgutterman@gmail.com",
  password: "davidgutterman123",
  role: "admin",
};

export const authoriseUser = (email, password) => {
  let res = {};

  if (email === DUMMY_USERS.email && password === DUMMY_USERS.password) {
    res.data = DUMMY_USERS;
    res.error = false;
  } else {
    res.data = {};
    res.error = true;
  }
  return res;
};
