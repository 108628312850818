import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useImageMeteDataContext } from "../context";

const OrderingList = ({ data }) => {
  const { state, dispatch } = useImageMeteDataContext();
  const location = useLocation();
  const navigate = useNavigate();

  let column = location.state.col === "search" ? "caption" : location.state.col;

  let sortedMeta;
  let uniqueMetaName;

  const handleOrderItemClick = (name) => {
    let uniqueMeta = sortedMeta.filter((data) => data[column].includes(name));
    // console.log(name);
    console.log(uniqueMeta);
    // console.log(column);
    dispatch({ type: "SEARCH", payload: uniqueMeta });

    navigate("/results", { state: { col: "search" } });
  };

  useEffect(() => {
    if (data.length === 0) {
      navigate("/search");
    }
  }, []);

  const modifiedMeta = data.map((m) => ({
    ...m,
    [column]: m[column].toUpperCase(),
  }));

  sortedMeta = modifiedMeta.sort((a, b) => (a[column] > b[column] ? 1 : -1));
  let uniqueMeta = new Set(sortedMeta.map((name) => name[column]));
  uniqueMetaName = [...uniqueMeta];

  return (
    <>
      {uniqueMetaName &&
        uniqueMetaName.map((name) => {
          return (
            <div className="">
              {/* Grid For Today */}
              <div className="grid grid-cols-1 gap-2">
                <div
                  onClick={() => {
                    handleOrderItemClick(name);
                  }}
                  className="text-left px-5 py-2 truncate w-full text-gray-600 text-xs bg-gray-200 mb-2 cursor-pointer"
                >
                  <p>{name}</p>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};
export default OrderingList;
