import React, { useEffect, useState } from "react";
import { useImageMeteDataContext } from "../context/index";
import {
  Loader,
  NoData,
  Table,
  Modal,
  ImageModal,
  ImageViewer,
} from "../components";
import { DATE } from "../utils/date";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { API } from "../service/Api";
import { showErrorToast, showSuccessToast } from "../utils/toast";

import { Controller, useForm } from "react-hook-form";
import CreatableSelect from "react-select/creatable";

const modalConfig = {
  title: "",
  handler: null,
  classes: "sm:max-w-3xl",
};

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const Dashboard = () => {
  const { setValue, register, handleSubmit, control, getValues } = useForm();
  const { state, getImageMeta, openModal, setOpenModal, openImageModal } =
    useImageMeteDataContext();

  const [inputValue, setInputValue] = React.useState("");
  const [values, setValues] = React.useState([]);
  const [image, setImage] = useState({});

  const [modalType, setModalType] = useState("edit");
  useEffect(() => {
    getImageMeta();
  }, []);

  const handleKeyDown = (event) => {
    const currentKeywords = getValues("keywords"); // get current value of 'keywords' field

    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (values?.find((value) => inputValue === value) !== undefined) {
          return;
        } else {
          setValues((prev) => [...prev, createOption(inputValue)]);
        }
        const newKeywords = [...currentKeywords, createOption(inputValue)];
        setValue("keywords", newKeywords);
        setInputValue("");
        event.preventDefault();
    }
  };

  const handleUpdateMetadata = async (data) => {
    setOpenModal(!openModal);
    let values = [];
    if (data) {
      if (data.keywords.length === 0) {
        values.push("N/A");
      } else {
        values = data.keywords.map((keyword) => keyword.value);
      }
      values = JSON.stringify(values);
      data = {
        ...data,
        keywords: values,
      };
    }

    try {
      const res = await API.putImageMeta(data);
      if (res.error) {
        throw new Error(res.error);
      } else {
        showSuccessToast(res.message);
        getImageMeta();
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleDeleteBtnClk = async (meta) => {
    try {
      const res = await API.deleteImageMeta(meta.id);
      if (res.error) {
        throw new Error(res.error);
      } else {
        showSuccessToast(res.message);
        getImageMeta();
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleUpdateBtnClk = (meta) => {
    setModalType("edit");

    modalConfig.title = "Update Metadata ";
    modalConfig.handler = handleUpdateMetadata;
    modalConfig.classes = " sm:max-w-lg";

    let _keywords = JSON.parse(meta.keywords);

    if (_keywords.length > 0 && _keywords[0] !== "N/A") {
      _keywords = _keywords.map(
        (keyword) => createOption(keyword)
        //setValues((prev) => [...prev, createOption(keyword)]);
      );
    } else {
      _keywords = [];
    }

    setValue("id", meta.id);
    setValue("object_name", meta.object_name);
    setValue("by_line", meta.by_line);
    setValue("caption", meta.caption);
    setValue("keywords", _keywords);
    setValue("writer", meta.writer);
    if (meta.date === "N/A") {
      setValue("date", "N/A");
    } else {
      setValue("date", DATE.to(meta.date));
    }

    setOpenModal(true);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "File",
        accessor: (row) => row,
        Cell: ({ value }) => (
          <div
            onClick={() => {
              modalConfig.title = "Image Detail";
              modalConfig.classes = " sm:max-w-6xl";
              setImage(value);
              setOpenModal(true);
              setModalType("image");
            }}
            className="h-24 w-24 overflow-hidden"
          >
            <img
              src={value.imageUrl}
              alt={value.imageUrl}
              className=" h-24 w-24 object-cover rounded-sm"
            />
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "image",
      },

      // {
      //   Header: "Caption",
      //   accessor: "object_name",
      // },
      {
        Header: "Keywords",
        accessor: "keywords",
        Cell: ({ value }) => {
          let keywords = JSON.parse(value);

          if (!Array.isArray(keywords)) {
            keywords = [keywords];
          }
          return (
            <div className="flex flex-wrap">
              {keywords.map((keyword, index) => (
                <span
                  key={index}
                  className="m-1 p-2 bg-gray-100 text-xs rounded-sm"
                >
                  {keyword}
                </span>
              ))}
            </div>
          );
        },
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ value }) => (
          <div className="max-h-24">
            {value !== "N/A" ? DATE.parser(value) : "N/A"}
          </div>
        ),
      },
      {
        Header: "Label",
        accessor: "by_line",
      },
      {
        Header: "Notes",
        accessor: "caption",
      },

      {
        Header: "Events / Places",
        accessor: "writer",
      },
    ],
    []
  );

  const actions = [
    {
      icon: PencilIcon,
      handler: handleUpdateBtnClk,
      styles: "w-5 h-5 text-green-500",
    },
    {
      icon: TrashIcon,
      handler: handleDeleteBtnClk,
      styles: "w-5 h-5 text-red-500",
    },
  ];

  const config = {
    isSorted: true,
    isPaged: true,
  };

  if (state.loading) {
    return <Loader />;
  }

  return (
    <>
      {openImageModal ? <ImageViewer /> : ""}

      {openModal ? (
        <Modal config={modalConfig}>
          {modalType === "edit" ? (
            <form onSubmit={handleSubmit(handleUpdateMetadata)}>
              <div className="space-y-6 sm:space-y-5">
                <input
                  type="text"
                  id="id"
                  hidden={true}
                  readOnly
                  {...register("id")}
                />

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="employee"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Caption
                  </label>

                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      id="object_name"
                      {...register("object_name")}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="department"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Label
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      id="by_line"
                      {...register("by_line")}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="shift"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Notes
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      id="caption"
                      {...register("caption")}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="start"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Keywords
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Controller
                      name="keywords"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <CreatableSelect
                            components={components}
                            inputValue={inputValue}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={onChange}
                            onInputChange={(newValue) =>
                              setInputValue(newValue)
                            }
                            onKeyDown={handleKeyDown}
                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                            value={value}
                          />
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="note"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Events / Places
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      id="writer"
                      {...register("writer")}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="note"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Date
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="date"
                      id="date"
                      {...register("date")}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  type="submit"
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                >
                  Save
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  onClick={() => setOpenModal(!openModal)}
                >
                  Cancel
                </button>
              </div>
            </form>
          ) : (
            <ImageModal image={image} />
          )}
        </Modal>
      ) : (
        ""
      )}

      {state.meta.length > 0 ? (
        <Table
          columns={columns}
          data={state?.meta}
          actions={actions}
          config={config}
        />
      ) : (
        <NoData message={"You dont have any image meta to show"} />
      )}
    </>
  );
};

export default Dashboard;
