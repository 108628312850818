export const initialState = {
  uploadedImages: [],
  meta: [],
  searchResults: [],
  loading: false,
  error: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPLOADED_IMAGES": {
      return {
        ...state,
        uploadedImages: action.payload,
      };
    }

    case "META": {
      return {
        ...state,
        meta: action.payload,
      };
    }

    case "SEARCH": {
      return {
        ...state,
        searchResults: action.payload,
      };
    }
    case "LOADING":
      return { ...state, loading: action.payload };

    case "ERROR":
      return { ...state, error: action.payload };

    default:
      return state;
  }
};
