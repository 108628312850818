import React from "react";

const NoData = ({ message }) => {
  return (
    <div className="flex flex-col  items-center justify-center">
      <div className="mt-16">
        <img
          className="h-24 w-24"
          src={require("../assets/box-empty-state.jpg")}
          alt="No Data Found"
        />
      </div>
      <div className="text-lg mt-6 text-gray-500 font-bold">Data Not Found</div>
      <p className="text-sm mt-3 text-gray-700">{message}</p>
    </div>
  );
};

export default NoData;
