import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ImageViewer from "./imageViewer";
import { ImageModal, Modal } from ".";
import { useImageMeteDataContext } from "../context";

const modalConfig = {
  title: "",
  handler: null,
  classes: "",
};

const ImageGrid = ({ data }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [image, setImage] = useState({});
  let column = location.state.col === "search" ? "image" : location.state.col;

  let sortedMeta;
  let uniqueFirstLetters;

  const { openModal, setOpenModal, openImageModal } = useImageMeteDataContext();

  useEffect(() => {
    if (data.length === 0) {
      navigate("/search");
    }
  }, []);
  // debugger;
  const modifiedMeta = data.map((m) => ({
    ...m,
    [column]: m[column].toUpperCase(),
  }));

  sortedMeta = modifiedMeta.sort((a, b) => (a[column] > b[column] ? 1 : -1));

  let firstLetters = new Set(sortedMeta.map((name) => name[column].charAt(0)));
  uniqueFirstLetters = [...firstLetters];

  const handleClickOnImage = (image) => {
    modalConfig.title = "Image Detail";
    modalConfig.classes = " sm:max-w-6xl";
    setImage(image);
    setOpenModal(true);
  };

  return (
    <>
      {openImageModal ? <ImageViewer /> : ""}

      {openModal ? (
        <Modal config={modalConfig}>
          <ImageModal image={image} />
        </Modal>
      ) : (
        ""
      )}

      {uniqueFirstLetters &&
        uniqueFirstLetters.map((letter) => {
          return (
            <div className="">
              {/* Grid For Today */}
              <div className="flex items-center gap-1 py-4">
                <p className="text-xl text-gray-600">{letter}</p>
                <div
                  className="flex-1 mx-5"
                  style={{ height: "1px", backgroundColor: "#dadada" }}
                ></div>
              </div>
              <div className="grid grid-cols-6 gap-4">
                {sortedMeta &&
                  sortedMeta
                    .filter((image) => image[column].startsWith(`${letter}`))
                    .map((a) => {
                      return (
                        <div className="grid place-items-center">
                          <div className=" w-56 h-36 overflow-hidden p-3">
                            <img
                              key={a.id}
                              src={a.imageUrl}
                              alt=""
                              className="rounded-sm p-2 object-cover w-full"
                              onClick={() => handleClickOnImage(a)}
                            />
                          </div>
                          <p className="text-center p-5 truncate w-full text-gray-600 text-xs">
                            {a[column]}
                          </p>
                        </div>
                      );
                    })}
              </div>
            </div>
          );
        })}
    </>
  );
};
export default ImageGrid;
