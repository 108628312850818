import axios from "axios";
import { showErrorToast } from "../utils/toast";

if (
  process.env &&
  process.env.NODE_ENV &&
  process.env.NODE_ENV === "production"
)
  axios.defaults.baseURL = "https://api.davidgutterman.com/";
else axios.defaults.baseURL = "http://localhost:4000/";

axios.interceptors.request.use(
  (config) => {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 401 &&
    error.response.status < 500;
  if (error.response && error.response.status === 400) {
    let errorMsg = error.response.data || "Some error occured.";
    showErrorToast(errorMsg);
  } else if (!expectedError) {
    showErrorToast("An unexpected error occured!");
  }
  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
